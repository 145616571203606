import { Dispatch, IToken, ITransfer } from './interfaces';

// Send an action to the reducer

export const updateContextAction = (dispatch: Dispatch, context: string) => {
  return dispatch({
    type: 'CONTEXT_UPDATED',
    payload: context,
  });
};

export const updateMMConnectionPendingAction = (
  dispatch: Dispatch,
  mmConnectionPending: boolean,
) => {
  return dispatch({
    type: 'MMCONNECTIONPENDING_UPDATED',
    payload: mmConnectionPending,
  });
};

export const updateRefreshingAction = (
  dispatch: Dispatch,
  refreshing: boolean,
) => {
  return dispatch({
    type: 'REFRESHING_UPDATED',
    payload: refreshing,
  });
};

export const updateContractAction = (dispatch: Dispatch, contract: string) => {
  return dispatch({
    type: 'CONTRACT_UPDATED',
    payload: contract,
  });
};

export const updateUserAction = (dispatch: Dispatch, user: string) => {
  return dispatch({
    type: 'USER_UPDATED',
    payload: user,
  });
};

export const updateTokensAction = (dispatch: Dispatch, tokens: IToken[]) => {
  return dispatch({
    type: 'TOKENS_UPDATED',
    payload: tokens,
  });
};

export const updateMyTokensAction = (dispatch: Dispatch, tokens: IToken[]) => {
  return dispatch({
    type: 'MYTOKENS_UPDATED',
    payload: tokens,
  });
};

export const updateTransferAction = (
  dispatch: Dispatch,
  transfer: ITransfer,
) => {
  return dispatch({
    type: 'TRANSFER_UPDATED',
    payload: transfer,
  });
};
