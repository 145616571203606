// The API is actually at :3001 but setupProxy redirects /cash calls to :3005

export let apiUrl: string;

if (process.env.NODE_ENV === 'development') {
  apiUrl = 'http://localhost:3000';
} else {
  apiUrl = 'https://';
}

export const configVars = {
  blockchainEndPoint:
    'https://mainnet.infura.io/v3/467b2e098b5e4d37a632294914ebe7fc',
  contractAddress:
    process.env.CONTRACT || '0xa5E5419B69133bfBa8e4f079b0BbCB07537F293A',
  homeImage: process.env.HOMEIMAGE || '/images/home-lg.jpg',
  youtubeLink:
    process.env.YOUTUBELINK || 'https://www.youtube.com/embed/DxmZ47cYKPg',
  ultimateTokenAvailableLink:
    process.env.ULTIMATEAVAILABLELINK ||
    'https://nft-media.hublot.com/media/football2021-ultimate-still-available.png',
  numberOfTokens: 9,
  chainId: 1, //connect to rinkeby 4 for testing. modify this to 1 for production
};

///the index of the array will be used as token ids. 0 is reserved for ultimate token
// This is unused for this version of the app
export const secretPhrases = [
  '0: ultimate token phrase',
  '1: Phrase 1',
  '2: Phrase 2',
  '3: Phrase 3',
  '4: Phrase 4',
  '5: Phrase 5',
  '6: Phrase 6',
  '7: Phrase 7',
  '8: Phrase 8',
  '9: Phrase 9',
  '10: Phrase 10',
  '11: Phrase 11',
  '12: Phrase 12',
];
