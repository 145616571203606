import React from 'react';
import { Store } from '../store/store-reducer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles({
  loadingComp: {
    background: '#F5F5F5  0% 0% no-repeat padding-box',
    borderRadius: '8px',
    opacity: 1,
    marginBottom: '40px',
    padding: '10px',
    textAlign: 'center',
  },
  logo: {
    top: '126px',
    left: '175px',
    width: '42px',
    height: '42px',
    opacity: 1,
  },
  loading: {
    textAlign: 'center',
    font: 'normal normal normal 12px/23px Gotham Bold',
    letterSpacing: '0.6px',
    color: '#000000',
    textTransform: 'uppercase',
    opacity: 1,
    fontWeight: 'bold',
  },
  loadingText: {
    font: 'normal normal normal 12px/23px Galaxie Copernicus',
    letterSpacing: '0px',
    opacity: 1,
  },
});

interface IProps {}

const Refreshing: React.FC<IProps> = () => {
  const classes = useStyles();
  
  // Get the State and the dispatch function
  const { state } = React.useContext(Store);
  
  const renderRefreshing = () => {
    return (
      <div>
        <Container className={classes.loadingComp}>
          <Typography className={classes.loading}>LOADING...</Typography>
          <img
            alt="HUBLOT"
            className={classes.logo}
            src="/images/loader.gif"
          />{' '}
          <br />
          <Typography noWrap={true} className={classes.loadingText}>
            Reading the Ethereum blockchain… <br />
            This could take up to 15 seconds
          </Typography>
        </Container>
      </div>
    );
  };

  if (state.storedRefreshing === true) {
    return renderRefreshing();
  } else {
    return null;
  }
};

export default Refreshing;
