import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  footerCopyright: {
    textAlign: "left",
    font: "italic normal normal 12px/19px Galaxie Copernicus Italic",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: 1
  },
  footerContent: {
    textAlign: "left",
    font: "12px/19px Galaxie Copernicus",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: 1,
    marginTop: '10px'
  },
  footer: {
    background: "#191919 0% 0% no-repeat padding-box",
    opacity: 1,
    padding: "1rem",
    position: "relative",
    bottom: 0,
    left: 0,
    marginTop: '200px',
  }

}));

interface IProps {}

const Footer: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Typography className={classes.footerCopyright}>
        © 2021 Hublot - All intellectual property rights reserved.
      </Typography>

      <Typography className={classes.footerContent}>
        This website is provided to facilitate access to information on the Ethereum blockchain and does store user data.
      </Typography>
    </div>  
  );
};

export default Footer;
