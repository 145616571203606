import React from 'react';
import { Store } from '../store/store-reducer';
import { IToken } from '../store/interfaces';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import Refreshing from './Refreshing';
import Transfer from './Transfer';

const useStyles = makeStyles((theme) => ({
  dashboardLayout: {
    marginBottom: '100px',
    marginTop: '10px',
    paddingTop: '5px',
    display: 'block',

    [theme.breakpoints.up('xs')]: {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '382px',
      margin: '0 Auto',
    },
    [theme.breakpoints.up('md')]: {
      width: '722px',
      margin: '0 Auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1162px',
      margin: '0 Auto',
    },
  },
  title: {
    textAlign: 'left',
    font: 'normal normal normal 30px/45px Gotham Black',
    letterSpacing: '1.5px',
    color: '#000000',
    textTransform: 'uppercase',
    opacity: 1,
    fontWeight: 'bold',
  },
  subtitle: {
    top: '142px',
    left: '14px',
    marginBottom: '10px',
    font: 'normal 16px/23px Gotham Black',
    letterSpacing: '0.8px',
    color: '#000000',
    textTransform: 'uppercase',
    opacity: 1,
    fontWeight: 'bold',
  },
  para: {
    textAlign: 'left',
    font: 'normal 12px/19px Galaxie Copernicus',
    letterSpacing: '0px',
    color: '#828282',
    opacity: '100%',
    marginBottom: '8px',
  },
  collection: {
    font: 'normal normal normal 12px/23px Gotham Bold',
    letterSpacing: '0.6px',
    color: '#000000',
    textTransform: 'uppercase',
    opacity: 1,
    fontWeight: 'bold',
  },
  collectionCount: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px',
    marginBottom: '10px',
  },
  walletAddress: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
    borderRadius: '8px',
    opacity: 1,
    marginTop: '15px',
    marginBottom: '35px',
    padding: '10px',
  },
  walletAddressCode: {
    textAlign: 'left',
    font: 'normal normal normal 12px/23px Galaxie Copernicus',
    letterSpacing: '0px',
    color: '#000000',
    opacity: 1,
  },
  ownedVideoGrid: {
    display: 'grid',
    'justify-content': 'center',
    [theme.breakpoints.up('xs')]: {
      'grid-template-columns': 'repeat(1, 1fr)',
    },
    [theme.breakpoints.up('sm')]: {
      'grid-template-columns': 'repeat(1, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      'grid-template-columns': 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      'grid-template-columns': 'repeat(3, 1fr)',
    },
  },
  notOwnedVideoGrid: {
    display: 'grid',
    'justify-content': 'center',
    [theme.breakpoints.up('xs')]: {
      'grid-template-columns': 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('sm')]: {
      'grid-template-columns': 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      'grid-template-columns': 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      'grid-template-columns': 'repeat(3, 1fr)',
    },
  },
  ownedVideoItem: {
    margin: '0 Auto',    
  },
  notOwnedVideoItem: {
    margin: '0 Auto',
  },
  videoStyles: {
    borderRadius: '8px',
    width: '350px',
    height: '350px',
  },
  videoStyles2: {
    borderRadius: '8px',
    [theme.breakpoints.up('xs')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '188px',
      height: '188px',
    },
    [theme.breakpoints.up('md')]: {
      width: '220px',
      height: '220px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '350px',
      height: '350px',
    },
  },
  ownedVideosDetails: {
    padding: '8px',
    paddingBottom: '20px',
  },
  buttonStyles: {
    height: '45px',
    background: '#000000 0% 0% no-repeat padding-box',
    textAlign: 'center',
    font: 'normal bold 12px/15px Gotham',
    letterSpacing: '1.2px',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    opacity: 1,
    marginTop: '15px',
    borderRadius: 0,
    marginBottom: '12px',
  },
  buttonStyles2: {
    height: '45px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    textAlign: 'center',
    font: 'normal bold 12px/15px Gotham',
    letterSpacing: '1.2px',
    color: '#171717',
    textTransform: 'uppercase',
    opacity: 1,
    marginTop: '15px',
    borderRadius: 0,
    marginBottom: '12px',
  },
  smallButtons: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '2px solid #000000',
    width: '144px',
    borderRadius: 0,
    height: '45px',
    textAlign: 'center',
    font: 'normal normal bold 12px/15px Gotham',
    letterSpacing: '1.2px',
    color: '#171717',
    textTransform: 'uppercase',
    opacity: 1,
  },
  smallButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface IProps {}

const Dashboard: React.FC<IProps> = () => {
  // Get the State and the dispatch function
  const { state } = React.useContext(Store);
  const classes = useStyles();

  const renderNotOwned = (tokens: IToken[]) => {
    return tokens.map((token) => {
      return renderToken(token);
    });
  };

  const renderToken = (token: IToken) => {
    return (
      <div key={token.id} className={classes.notOwnedVideoItem}>
        <video className={classes.videoStyles2} poster={token.image}></video>
      </div>
    );
  };

  const ownedMissing = (myTokens: IToken[], otherTokens: IToken[]) => {
    return (
      <div className={classes.dashboardLayout}>
        <Refreshing />
        <Typography gutterBottom className={classes.title}>
          My NFTs
        </Typography>
        <Typography className={classes.subtitle}>
          Hublot Loves Football 2021
        </Typography>
        <Typography className={classes.para}>
          Values of Victory with Hublot.
        </Typography>
        <Typography className={classes.collection}>
          Complete collection: {myTokens.length + otherTokens.length}
        </Typography>

        <Container maxWidth="lg" className={classes.walletAddress}>
          <Typography className={classes.collection}>
            My wallet address
          </Typography>
          <Typography className={classes.walletAddressCode}>
            {state.storedUser}
          </Typography>
        </Container>

        <div className={classes.collectionCount}>
          <Typography className={classes.collection}>
            Owned {myTokens.length}
          </Typography>
          <Typography className={classes.collection}>
            Not Owned {otherTokens.length}
          </Typography>
        </div>

        <div>
          <div className={classes.ownedVideoGrid}>
            {myTokens.map((token) => (
              <div key={token.id} className={classes.ownedVideoItem}>
                <video
                  src={token.animation_url}
                  poster={token.image}
                  className={classes.videoStyles}
                ></video>
                <div className={classes.ownedVideosDetails}>
                  <Typography className={classes.para}>
                    {token.description}
                  </Typography>

                  <Button
                    className={classes.buttonStyles}
                    variant="contained"
                    disableElevation={true}
                    fullWidth={true}
                    href={token.animation_url}
                    target="_blank"
                  >
                    Listen
                  </Button>
                  <div className={classes.smallButtonsContainer}>
                    <Button
                      className={classes.buttonStyles2}
                      variant="outlined"
                      disableElevation={true}
                      fullWidth={true}
                      href={
                        'https://opensea.io/assets/' +
                        state.storedContract +
                        '/' +
                        token.id
                      }
                      target="_blank"
                    >
                      in opensea
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Typography className={classes.collection}>
          NFTS NOT OWNED FROM THIS COLLECTION
        </Typography>

        <div>
          <div className={classes.notOwnedVideoGrid}>
            {renderNotOwned(otherTokens)}            
          </div>
        </div>
      </div>
    );
  };

  if (state.storedContext === 'holdings' && !(state.storedUser === '')) {
    var myTokens: IToken[] = state.myTokens;
    var otherTokens: IToken[] = state.storedTokens.filter(function (
      item: IToken,
    ) {
      return !myTokens.includes(item);
    });

    return (
      <div className={classes.dashboardLayout}>
        {ownedMissing(myTokens, otherTokens)}
      </div>
    );
  } else {
    if (state.storedContext === 'transfer' && !(state.storedUser === '')) {
      return (
        <div className={classes.dashboardLayout}>
          <Transfer />
        </div>
      );
    } else {
      return null;
    }
  }
};

export default Dashboard;
