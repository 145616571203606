import React from 'react';
import { IAction, IState } from './interfaces';

const initialState: IState = {
  storedContext: 'default',
  storedMMConnectionPending: false,
  storedRefreshing: false,
  storedContract: '',
  storedUser: '',
  storedTokens: [],
  myTokens: [],
  storedTransfer: {
    from: '',
    to: '',
    id: 0,
    name: '',
    balance: 0,
    status: 'idle',
  },
};

export const Store = React.createContext<IState | any>(initialState);

// The reducer takes the state and applies the action(s) to it in order to modify the store
// Each action has a type and payload
function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case 'CONTEXT_UPDATED':
      return { ...state, storedContext: action.payload };
    case 'MMCONNECTIONPENDING_UPDATED':
      return { ...state, storedMMConnectionPending: action.payload };
    case 'REFRESHING_UPDATED':
      return { ...state, storedRefreshing: action.payload };
    case 'CONTRACT_UPDATED':
      return { ...state, storedContract: action.payload };
    case 'USER_UPDATED':
      return { ...state, storedUser: action.payload };
    case 'TOKENS_UPDATED':
      return { ...state, storedTokens: action.payload };
    case 'MYTOKENS_UPDATED':
      return { ...state, myTokens: action.payload };
    case 'TRANSFER_UPDATED':
      return { ...state, storedTransfer: action.payload };
    default:
      return state;
  }
}

// This is used to inject the Store at the top level in the index.tsx file
export function StoreProvider(props: any): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <Store.Provider value={{ state, dispatch }}>
      {props.children}
    </Store.Provider>
  );
}
