import React from 'react';
import { Store } from '../store/store-reducer';
import { Button, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { updateContextAction, updateTransferAction } from '../store/actions';
import { ethers } from 'ethers';
import * as contractJson from '../config/contracts/nft.json';
import * as config from '../config/config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    background: "#F5F5F5  0% 0% no-repeat padding-box",
    borderRadius: "8px",
    opacity: 1,
    marginBottom: "40px",
    padding: "25px",
    textAlign: "center"
  },
  gothamBold: {
    textAlign: "left",
    font: "normal normal normal 12px/23px Gotham Bold",
    letterSpacing: "0.6px",
    color: "#000000",
    textTransform: "uppercase",
    opacity: 1,
  },
  gothamBlack: {
    textAlign: "left",
    font: "normal normal normal 16px/23px Gotham Black",
    letterSpacing: "0.8px",
    color: "#000000",
    textTransform: "uppercase",
    opacity: 1,
  },
  gotham: {
    textAlign: "left",
    font: "normal normal 10px/15px Gotham Black",
    letterSpacing: "1px",
    color: "#191919",
    textTransform: "uppercase",
    opacity: 1,
  },
  galaxieText: {
    textAlign: "left",
    font: "normal normal normal 12px/23px Galaxie Copernicus",
    letterSpacing: "0px",
    opacity: 1
  },
  galaxieTextRed: {
    textAlign: "left",
    font: "normal normal normal 12px/23px Galaxie Copernicus",
    letterSpacing: "0px",
    color: "#DE2B2B",
    opacity: 1
  },
  textBox: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #949494",
    opacity: 1,
    width: "100%"
  },
  button: {
    background: "#000000 0% 0% no-repeat padding-box",
    border: "1px solid #000000",
    opacity: 1,
    width: "100%"
  }
});

interface IProps {}

const Transfer: React.FC<IProps> = () => {
  const classes = useStyles();
  // Get the State and the dispatch function
  const { state, dispatch } = React.useContext(Store);

  const updateTo = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newTransfer = {
      ...state.storedTransfer,
      to: event.target.value as string,
    };
    updateTransferAction(dispatch, newTransfer);
  };

  const getWriteContractInstance = async (
    endpoint: string,
    contractAddress: string,
  ): Promise<ethers.Contract> => {
    
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    
    const contractAbi = contractJson.abi;
    const contractContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      ethersProvider,
    );
    await window.ethereum.enable(); //connect Metamask
    
    const fromSigner = ethersProvider.getSigner();
    const fromSignerContract = contractContract.connect(fromSigner);
    return fromSignerContract;
  };

  const handleTransfer = async () => {
    const addresses = await window.ethereum.enable(); //connect Metamask
    const newTransfer = {
      ...state.storedTransfer,
      from: addresses[0],
      balance: 1,
      status: 'sent',
    };
    const writeContract = await getWriteContractInstance(
      config.configVars.blockchainEndPoint,
      state.storedContract,
    );
    await writeContract.safeTransferFrom(
      newTransfer.from,
      newTransfer.to,
      newTransfer.id,
      1,
      '0x',
    );
    updateTransferAction(dispatch, newTransfer);
  };

  const returnToHoldings = () => {
    const newTransfer = {
      from: '',
      to: '',
      id: 0,
      name: '',
      balance: 0,
      status: 'idle',
    };
    updateTransferAction(dispatch, newTransfer);
    updateContextAction(dispatch, 'holdings');
  };

  const renderTransfer = () => {
    const storedTransfer = state.storedTransfer;
    switch (storedTransfer.status) {
      case 'idle':
        return (
          <div>
            <Container className={classes.container}>
              <Typography  className={classes.gothamBlack} >
              Transfert my NFT to another Ethereum address
              </Typography>
              <br />
              <Typography noWrap={false} className={classes.galaxieText} >
              Be careful. This operation will require the payment of transaction fee to the blockchain network and is irreversible. You will lose ownership of the NFT forever.
              </Typography>
              <br />
              <Typography  className={classes.gothamBold} >
              Transfer Instructions
              </Typography>
              <br />
              <Typography  className={classes.galaxieTextRed} >
              You are preparing to transfer one unit of NFT #{storedTransfer.id} named « {storedTransfer.name} »
              </Typography>
              <br />
              <Typography  className={classes.gotham} >
              TO (ADDRESS)
              </Typography>
              <TextField
                id="standard-basic"
                value={state.storedTransfer.to}
                type="string"
                onChange={updateTo}
                className={classes.textBox}                
              />              
              <Button
               style={{ marginTop: 20 }}
                variant="contained"
                color="primary"
                onClick={handleTransfer}
                className={classes.button}
              >
                Sign transfer
              </Button>
            </Container>
          </div>
        );
      case 'sent':
        return (
          <div>
            <Container className={classes.container}>
              <Typography  className={classes.gothamBlack} >
              The Transfer has been ordered
              </Typography>
              <br />
              <Typography noWrap={false} className={classes.galaxieTextRed} >
              You have requested the transfer one unit of the NFT ##{storedTransfer.id} named « {storedTransfer.name} » to address {storedTransfer.to}. The transfert is irreversible and will be reflected in your account once it has been processed by the Ethereum blockchain network
              </Typography>
              <br />
              <Typography  className={classes.galaxieText} >
              Don’t forget to click the Refresh menu option when you are back to the « My NFTs » page, in order to check your updated holdings.
              </Typography>
              <br />
              <Button
               style={{ marginTop: 20 }}
                variant="contained"
                color="primary"
                onClick={returnToHoldings}
                className={classes.button}
              >
                Acknowledged
              </Button>
            </Container>
          </div>
        );
      default:
        return null;
    }
  };

  if (state.storedContext === 'transfer') {
    return renderTransfer();
  } else {
    return null;
  }
};

export default Transfer;
