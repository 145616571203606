import React from 'react';
import { Store } from '../store/store-reducer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as config from '../config/config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gothamBlack: {
      paadingTop: '10px',
      textAlign: 'left',
      font: 'normal normal normal 30px/45px Gotham Black',
      letterSpacing: '1.5px',
      color: '#000000',
      textTransform: 'uppercase',
      opacity: 1,
    },
    gothamBlack2: {
      textAlign: 'center',
      font: 'normal normal normal 24px/23px Gotham Black',
      letterSpacing: '1.2px',
      color: '#000000',
      textTransform: 'uppercase',
      opacity: 1,
    },
    galaxie: {
      textAlign: 'left',
      font: 'normal normal normal 12px/19px Galaxie Copernicus',
      letterSpacing: '0px',
      color: '#797979',
      opacity: 1,
    },
    galaxie2: {
      textAlign: 'left',
      font: 'normal normal normal 12px/19px Galaxie Copernicus',
      letterSpacing: '0px',
      color: '#797979',
      opacity: 1,
    },
    galaxie3: {
      textAlign: 'left',
      font: 'normal normal bold 12px/19px Galaxie Copernicus',
      letterSpacing: '0px',
      color: '#797979',
      opacity: 1,
    },
    topMargin: {
      marginTop: '30px',
    },
    center: {
      justifyContent: 'space-around',
    },
    smallButtons: {
      background: '#000000 0% 0% no-repeat padding-box',
      border: '1px solid #000000',
      width: '100%',
      borderRadius: 0,
      height: '45px',
      textAlign: 'center',
      font: 'normal normal bold 12px/15px Gotham',
      letterSpacing: '1.2px',
      color: '#FFFFFF',
      textTransform: 'uppercase',
      opacity: 1,
    },
    Lg: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    Sm: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    bar: {
      background: '#000000',
    },
    video: {
      border: '1px solid',
      display: 'block',
      [theme.breakpoints.up('xs')]: {
        width: '300px',
        height: '200px',
        margin: '0 Auto',
      },
      [theme.breakpoints.up('sm')]: {
        width: '505px',
        heigth: '280px',
        margin: '0 Auto',
      },
    },
    homeImage: {
      width: '100%',
      height: '300px',
      'object-fit': 'cover',
    },
  }),
);

interface IProps {}

const Home: React.FC<IProps> = () => {
  const classes = useStyles();
  // Get the State and the dispatch function
  const { state } = React.useContext(Store);

  const renderHome = () => {
    if (state.storedUser === '') {
      return (
        <div>
          <img
            className={classes.homeImage + ' ' + classes.Lg}
            src="/images/home-lg.jpg"
            alt="Hublot"
          />
          <img
            className={classes.homeImage + ' ' + classes.Sm}
            src="/images/home-sm.jpg"
            alt="Hublot"
          />
          <Container className={classes.center}>
            <Typography
              className={classes.gothamBlack + ' ' + classes.topMargin}
            >
              HUBLOT LOVES FOOTBALL 2021 COLLECTION
            </Typography>
            <br />
            <Typography className={classes.galaxie}>
              Values of Victory with Hublot.
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              In line with Hublot’s “First, Different, Unique” philosophy, we
              are delighted to offer a Hublot Loves Football 2021 NFT to
              selected customers along with your watch.
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              In order to access your NFT, you must retrieve your account
              credential (private key). This account credential can only be
              retrieved once for security reasons. Make sure to save your
              credential in a safe and secure location. If you lose your account
              credential, you will lose access to your NFT.
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              The process can be complicated if you are not familiar with
              blockchain technology, so please carefully follow the steps
              explained below:
            </Typography>
            <br />
            <Typography className={classes.galaxie3}>
              Step 1 - Retrieve your account credential (private key):
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              After having registered your watch on Hublotista, you will
              complete the following actions in the Hublotista app:
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              a) Confirm terms and conditions
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              b) Enter the security key that you received by e-mail. Please note
              that this security key is not your account credential.
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              c) Retrieve and save the account credential (private key) in a
              safe and secure location.
            </Typography>
            <br />
            <Typography className={classes.galaxie3}>
              Step 2 - Access your NFT.
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              a) Install the{' '}
              <a href=" http://metamask.io" target="_blank">
                MetaMask
              </a>{' '}
              app and create your wallet
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              b) Import the account credentials provided by Hublot. See the
              Youtube video below for more detailed instructions.
            </Typography>
            <br />
            <Typography className={classes.galaxie2}>
              c) Open the MetaMask browser and type the address nft.hublot.com
            </Typography>
            <br />
            <Typography className={classes.galaxie3}>
              Congratulations on the purchase of your watch with NFT and welcome
              to the Hublot Family!
            </Typography>
            <br />
            <Button
              className={classes.smallButtons + ' ' + classes.Lg}
              variant="contained"
              disableElevation={true}
              fullWidth={true}
              onClick={() => {
                window.location.reload();
              }}
            >
              Log in with your wallet
            </Button>
            <Button
              className={classes.smallButtons + ' ' + classes.Sm}
              variant="contained"
              disableElevation={true}
              fullWidth={true}
              onClick={() => {
                window.location.reload();
              }}
            >
              Log in
            </Button>
            <br />
            <br />
            <Typography className={classes.gothamBlack2}>
              HOW TO CREATE A METAMASK ACCOUNT
            </Typography>
            <br />
            <br />
            <div className={classes.video}>
              <iframe
                title="Hublot"
                className={classes.video}
                src={config.configVars.youtubeLink}
                allow="fullscreen;"
              ></iframe>
            </div>
          </Container>
        </div>
      );
    } else {
      return null;
    }
  };

  if (state.storedContext === 'default') {
    return renderHome();
  } else {
    return null;
  }
};

export default Home;
