import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  updateContextAction,
  updateContractAction,
  updateMyTokensAction,
  updateRefreshingAction,
  updateTokensAction,
  updateUserAction,
} from '../store/actions';
import { Store } from '../store/store-reducer';

import axios from 'axios';
import { ethers } from 'ethers';
import * as contractJson from '../config/contracts/nft.json';
import * as config from '../config/config';
import { IToken } from '../store/interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    center: {
      justifyContent: 'space-around',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    bar: {
      background: '#000000',
      height: '55px'
    },
    item: {
      'paddingTop': '25px', 
    },
    left: {
      'float': 'left',
      paddingLeft: '10px',
    },
    right: {
      'float': 'right',
      paddingRight: '10px',
    },
    icon: {
      //'paddingTop': '1px',
    },
    text: {
      fontSize: '10px',
      paddingTop: '2px',
      'letter-spacing': '2px',
    },
    Lg: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',        
      },
    },
    Sm: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

interface IProps {}

interface IMetadata {
  name: string;
  description: string;
  external_url: string;
  image: string;
  animation_url: string;
}

declare global {
  interface Window {
    ethereum: any;
  }
}

const Header: React.FC<IProps> = () => {
  const classes = useStyles();

  // Get the State and the dispatch function
  const { state, dispatch } = React.useContext(Store);

  React.useEffect(() => {
    async function initialLoad() {
      await connectMetamaskAndLoad();
    }
    initialLoad();
  }, []);

  const getReadContractInstance = async (
    endpoint: string,
    contractAddress: string,
  ): Promise<ethers.Contract> => {
    const blockchainEndPoint = endpoint;
    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    const ethersProvider = new ethers.providers.JsonRpcProvider(
      blockchainEndPoint,
    );

    const contractAbi = contractJson.abi;
    const contractContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      ethersProvider,
    );
    return contractContract;
  };

  const loadTokens = async (walletAddress: string) => {
    try {
      updateRefreshingAction(dispatch, true);
      updateContractAction(dispatch, config.configVars.contractAddress);
      const readContract = await getReadContractInstance(
        config.configVars.blockchainEndPoint,
        config.configVars.contractAddress,
      );

      const allTokens: IToken[] = [];
      var ultimateTokenRewarded: boolean = false;
      for (let i = 0; i <= config.configVars.numberOfTokens; i++) {
        if (i === 0) {
          //if ultimate token
          ultimateTokenRewarded = await readContract.ultimateTokenClaimed();
          //console.log('ultimate token = ' + ultimateTokenRewarded);
          if(ultimateTokenRewarded !== true) {
             continue;
          }
        }
        const balanceBN = await readContract.balanceOf(walletAddress, i);
        const balance = parseInt(ethers.BigNumber.from(balanceBN).toString());

        let name = '';
        let description = '';
        let external_url = '';
        let image = '';
        let animation_url = '';
        
        try {
          const uri: string = (await readContract.uri(i)).toString();
          const response = await axios.request<IMetadata>({
            method: 'get',
            url: uri,
          });
          const metadata = response.data;
          name = metadata.name;
          description = metadata.description;
          external_url = metadata.external_url;
          image =
            i === 0 && !ultimateTokenRewarded
              ? config.configVars.ultimateTokenAvailableLink
              : metadata.image;
          animation_url = metadata.animation_url;
        } catch (e) {}
        
        const token = {
          id: i,
          balance,
          name,
          description,
          external_url,
          image,
          animation_url,
          phrase: '',
        };
        allTokens.push(token);
      }
      const myTokens: IToken[] = [];
      for (let j = 0; j < allTokens.length; j++) {
        if (allTokens[j].balance > 0) {
          var tok = allTokens[j];
          tok.phrase = config.secretPhrases[tok.id];
          myTokens.push(tok);
        }
      }
      updateRefreshingAction(dispatch, false);
      updateTokensAction(dispatch, allTokens);
      updateMyTokensAction(dispatch, myTokens);
    } catch (e) {
      //console.log(e);
      window.alert('An error occured loading token details from the network.');
    }
  };

  const handleClickRefresh = async () => {
    await connectMetamaskAndLoad();
  };

  const connectMetamaskAndLoad = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      }); //connect Metamask
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      const chains = ['', 'main', 'kovan', 'ropsten', 'rinkeby', 'Goerli'];

      window.ethereum.on('chainChanged', (chainId: any) => {
        // Correctly handling chain changes can be complicated. We recommend reloading the page unless you have good reason not to.
        window.location.reload();
      });

      window.ethereum.on('accountsChanged', (updatedAccounts: any) => {
        // Handle the new accounts, or lack thereof.
        // "accounts" will always be an array, but it can be empty.
        window.location.reload();
      });

      if (network.chainId !== config.configVars.chainId) {
        window.alert(
          "Please connect to '" +
            chains[config.configVars.chainId] +
            "' network on your MetaMask wallet. You are connected to '" +
            network.name +
            "'.",
        );
        return;
      }

      loadTokensForAccount(accounts[0]);
    } catch (e) {
      //console.log(e);
      window.alert(
        'In order to log in, please install the MetaMask wallet at metamask.io and set it up in your browser.',
      );
      return;
    }
  };

  const loadTokensForAccount = (address: string) => {
    updateUserAction(dispatch, address);
    updateContextAction(dispatch, 'holdings');

    try {
      loadTokens(address);
    } catch (e) {
      //console.log(e);
      window.alert('Error in loading tokens for the wallet ' + address);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.bar}>
        <Toolbar className={classes.center + ' ' + classes.Lg}>
          <Grid container spacing={3}>
            <Grid item sm={8} className={classes.item}>
              <img src="/images/Hublot-logo.svg" alt='Hublot' />
            </Grid>
            <Grid item sm={2} className={classes.item}>
            { state.storedUser && (
              <>
                <img src="/images/refresh.svg" onClick={handleClickRefresh} className={classes.left + ' ' + classes.icon} alt='Refresh' />
                <Typography className={classes.left + ' ' + classes.text}>REFRESH</Typography>
              </>
            )}
            </Grid>
            <Grid item sm={2} className={classes.item}>              
              { state.storedUser && (
                  <Typography className={classes.right + ' ' + classes.text}>{('' + state.storedUser).slice(0, 10)}</Typography>                
              )}
              { !state.storedUser && (
                  <Typography className={classes.right + ' ' + classes.text} onClick={() => {
                    window.location.reload();
                  }}>LOGIN</Typography>                
              )}
              <img src="/images/User-icon.svg" className={classes.right + ' ' + classes.icon} alt='' />
            </Grid>
          </Grid>
        </Toolbar>
        <Toolbar className={classes.center + ' ' + classes.Sm}>
          <img alt='Refresh' src="/images/refresh.svg" onClick={handleClickRefresh} />
          <img src="/images/Hublot-logo.svg" alt='Hublot' />
          <img src="/images/User-icon.svg" alt='' />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
