import Footer from './Footer';
import Header from './Header';
import Home from './Home';
import Dashboard from './Dashboard';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 382,
      md: 772,
      lg: 1162,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      'Gotham',
      'Gotham Bold',
      'Gotham Black',
      'Galaxie Copernicus',
      'Galaxie Copernicus Italic',
    ].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Header />
        <Home />
        <Dashboard />        
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
